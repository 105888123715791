/* start vars: m-listings-lastupdated */
/* line 13, scss/80-themes/Saunders/70-modules/listingslastupdatedmodule/module.scss */
.m-lastupdated {
	font-size: 0.85em;
	text-align: center;
	padding: 10px 0;
	color: rgb(var(--color-global-text));
}

@media only screen and (max-width: 47.999em) {
	/* line 13, scss/80-themes/Saunders/70-modules/listingslastupdatedmodule/module.scss */
	.m-lastupdated {
		font-size: 0.85em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 13, scss/80-themes/Saunders/70-modules/listingslastupdatedmodule/module.scss */
	.m-lastupdated {
		font-size: 0.85em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 13, scss/80-themes/Saunders/70-modules/listingslastupdatedmodule/module.scss */
	.m-lastupdated {
		font-size: 0.85em;
	}
}

/* line 21, scss/80-themes/Saunders/70-modules/listingslastupdatedmodule/module.scss */
.lastupdated__time {
	color: rgb(var(--color-global-positive));
}

/*# sourceMappingURL=../../../../../true */